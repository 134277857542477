import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ThemeProvider } from './hooks/themeHook/themeContext';

// const Root = () => {
//   return (
  
//   );
// };

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider>
  <App />
</ThemeProvider>
);

reportWebVitals();
serviceWorkerRegistration.register();
